import { PageRendererProps } from "gatsby";
import * as React from "react";
import Layout from "../layouts/layout";

interface PostTemplateProps extends PageRendererProps {
  pageContext: any;
  data: any;
}

export default class PostTemplate extends React.Component<PostTemplateProps> {
  public render() {
    return (
      <Layout layoutParams={this.props.pageContext.layoutParams}>
        404 Not found
      </Layout>
    );
  }
}
